import { defineStore } from "pinia";

import { ref } from "vue";

import { useI18n } from "vue-i18n";

import de from "quasar/lang/de";
import en from "quasar/lang/en-US";
import it from "quasar/lang/it";
import es from "quasar/lang/es";

import { useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";

import { api } from "boot/axios";

export const useMainStore = defineStore("main", () => {
  const quasar = useQuasar();
  const { t, locale } = useI18n();

  const router = useRouter();
  const route = useRoute();

  const quasarLanguagePacks = {
    en,
    de,
    it,
    es,
  };

  const account = ref(null);
  const client = ref(null);
  const user = ref(null);
  const project = ref({
    id: null,
    name: "Overview",
  });
  const projects = ref(null);

  const setProjects = (_projects) => (projects.value = _projects);
  const setAccount = (_account) => (account.value = _account);
  const setClient = (_client) => {
    client.value = _client;
    if (_client) localStorage.setItem("client", JSON.stringify(_client));
    else localStorage.removeItem("client");
  };
  const setUser = (_user) => {
    user.value = _user;
    if (_user) localStorage.setItem("user", JSON.stringify(_user));
    else localStorage.removeItem("user");
  };
  const setProject = (_project) => (project.value = _project);

  const setLocale = (_locale) => {
    locale.value = _locale;
    quasar.lang.set(
      quasarLanguagePacks[_locale.split("-").slice(0, 1).join("")]
    );
    localStorage.setItem("locale", _locale);
  };

  const loadLocale = async (defaultLocale, availableLocales) => {
    const _locale = localStorage.getItem("locale");
    if (_locale && !availableLocales.includes(_locale)) {
      setLocale(defaultLocale);
    } else setLocale(_locale ?? defaultLocale);
  };

  const getAccount = async () => {
    try {
      quasar.loading.show();

      const { accountSlug } = route.params;

      const response = await api.post(
        "actionSales",
        {
          action: "getAccount",
          body: {
            accountSlug,
            target: "sales",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setAccount(response.data.data);
      loadUser();
      loadLocale(response.data.data.locales[0], response.data.data.locales);
    } catch (error) {
      quasar.notify({
        color: "negative",
        message: error.response.data,
      });
      console.log(error);
    } finally {
      quasar.loading.hide();
    }
  };

  const loadUser = async () => {
    const _user = localStorage.getItem("user");
    const _client = localStorage.getItem("client");
    console.log("loadUser", _user, _client);
    if (_user && _client) {
      setUser(JSON.parse(_user));
      setClient(JSON.parse(_client));
      // await getAccount();
    }
  };

  const clearUser = () => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("client");
    setUser(null);
    setClient(null);
    setProject(null);

    const { accountSlug } = route.params;

    router.push({
      name: "login",
      params: {
        accountSlug,
      },
    });
  };

  return {
    project,
    setProject,
    setAccount,
    account,
    setClient,
    client,
    user,
    setUser,
    setLocale,
    loadLocale,
    loadUser,
    getAccount,
    clearUser,
    setProjects,
    projects,
  };
});
