<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { defineComponent, watch } from "vue";

import { useQuasar } from "quasar";

import { api } from "boot/axios";

import { useRouter, useRoute } from "vue-router";

import { useMainStore } from "stores/main";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "App",
  setup() {
    const quasar = useQuasar();

    const router = useRouter();
    const route = useRoute();

    const mainStore = useMainStore();
    const { user, client } = storeToRefs(mainStore);

    // mainStore.loadLocale();

    const getClient = async (email, password, projectId) => {
      try {
        quasar.loading.show();
        const { accountSlug } = route.params;

        console.log(email, password, projectId);
        const response = await api.post(
          "actionSales",
          {
            action: "getClient",
            body: {
              accountSlug,
              email,
              password,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        mainStore.getAccount();
        mainStore.setClient(response.data.data);
        mainStore.setUser({
          email,
          password,
        });
        if (projectId)
          router.push({
            name: "project",
            params: { accountSlug, projectId },
          });
        else
          router.push({
            name: "overview",
            params: {
              accountSlug,
            },
          });
      } catch (error) {
        console.log(error);
        quasar.notify({
          color: "negative",
          message: error.message,
        });
      } finally {
        quasar.loading.hide();
      }
    };

    watch(
      () => route.params.accountSlug,
      async (accountSlug) => {
        console.log(route.query);
        if (!accountSlug) router.push("/error");
        else if (accountSlug && route.query.user && route.query.pass) {
          await getClient(
            decodeURIComponent(route.query.user),
            decodeURIComponent(route.query.pass),
            route.params.projectId
          );
          console.log("here 2");
        } else if (accountSlug) {
          mainStore.getAccount();
          // mainStore.loadUser();
          console.log("here");
        }
      }
    );
  },
});
</script>
